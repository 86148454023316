import { useLocation } from "@reach/router";
import clsx from "clsx";
import { motion } from "framer-motion";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { forwardRef } from "react";
import { useFilterStore, useLocationStore, useNavStore, useProjectStore } from "../state/useStore";
import Filter from "./Filter";

const Footer = forwardRef(() => {
	const location = useLocation();
	const isProject = useLocationStore((state) => state.isProject);
	const title = useProjectStore((state) => state.title);
	const toggleInfo = useProjectStore((state) => state.toggleInfo);
	const info = useProjectStore((state) => state.info);
	const {
		allSanityCategory: { categories },
	} = useStaticQuery(getData);
	const filters = useFilterStore((state) => state.filters);
	const setExit = useNavStore((state) => state.setExit);

	return (
		<motion.footer
			className={clsx("fixed bottom-0 w-full h-12 px-4  flex-shrink-0 z-40  ", "md:px-12")}
		>
			{isProject && <div className={"absolute w-screen h-full bg-white left-0 -z-10"}></div>}
			<div className="h-full grid grid-cols-2 items-center bg-white">
				<div>
					{isProject && (
						<button onClick={toggleInfo}>
							<h3 className={" hover:text-brand"}>
								{info ? (
									<span>Close</span>
								) : (
									<span className={"uppercase"}>{title}</span>
								)}
							</h3>
						</button>
					)}
				</div>
				<div className={"text-right"}>
					{location.pathname === "/grid/" ? (
						<div className={"flex justify-end"}>
							<Filter active={filters.length === 0 || filters.includes("All")}>
								All
							</Filter>
							{categories.map((category, index) => {
								return (
									<Filter
										key={category._id}
										last={index === categories.length - 1}
										active={filters.includes(category.name)}
									>
										{category.name}
									</Filter>
								);
							})}
						</div>
					) : (
						<button
							aria-label="Grid View"
							className={clsx("hover:text-brand", {
								"opacity-0 pointer-events-none ":
									location.pathname === "/grid/" ||
									location.pathname === "/info/",
							})}
							onClick={() => {
								setExit(0);
								navigate("/grid", { state: { direction: "default" } });
							}}
						>
							Index
						</button>
					)}
				</div>
			</div>
		</motion.footer>
	);
});

export default Footer;

const getData = graphql`
	{
		allSanityCategory(sort: { orderRank: ASC }) {
			categories: nodes {
				_id
				name
			}
		}
	}
`;
