import create from "zustand";

export const useCursorStore = create((set) => ({
	visible: false,
	direction: "left",
	setVisible: (boolean) => set(() => ({ visible: boolean })),
	setDirection: (string) => set(() => ({ direction: string })),
}));

export const useScrollStore = create((set) => ({
	direction: "up",
	setDirection: (string) => set(() => ({ direction: string })),
}));

export const useLocationStore = create((set) => ({
	location: "/",
	lastLocation: "/",
	isProject: false,
	setLocation: (string) => set(() => ({ location: string })),
	setLastLocation: (string) => set(() => ({ lastLocation: string })),
	setIsProject: (boolean) => set(() => ({ isProject: boolean })),
}));

export const useProjectStore = create((set) => ({
	title: "",
	info: false,
	setTitle: (string) => set(() => ({ title: string })),
	toggleInfo: () => set((state) => ({ info: !state.info })),
}));

export const useNavStore = create((set) => ({
	previous: "",
	next: "",
	exit: 0,
	slideForwards: 0,
	slideBackwards: 0,
	setPrevious: (string) => set(() => ({ previous: string })),
	setNext: (string) => set(() => ({ next: string })),
	setExit: (string) => set(() => ({ exit: string })),
	goSlideForwards: () => set((state) => ({ slideForwards: state.slideForwards + 1 })),
	goSlideBackwards: () => set((state) => ({ slideBackwards: state.slideBackwards + 1 })),
}));

export const useFilterStore = create((set) => ({
	filters: [],
	toggleFilter: (f) =>
		set((state) =>
			state.filters.includes(f)
				? { filters: state.filters.filter((filter) => filter !== f) }
				: { filters: [...state.filters, f] }
		),
	clearFilters: () => set({ filters: [] }),
}));
