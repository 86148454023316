import clsx from "clsx";
import React from "react";

const Chevron = ({ className, direction }) => {
	return direction === "y" ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30.44"
			height="13.72"
			viewBox="0 0 30.44 13.72"
			className={clsx(className)}
		>
			<polygon
				points=".35 10.93 15.22 .31 30.09 10.93 28.35 13.37 15.22 3.99 2.09 13.37 .35 10.93"
				fill="#000"
				stroke="#fff"
				strokeMiterlimit="10"
				strokeWidth="0.5"
				shapeRendering="geometricPrecision"
			/>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.72"
			height="30.44"
			viewBox="0 0 13.72 30.44"
			className={clsx(className)}
		>
			<polygon
				points="10.93 30.09 .31 15.22 10.93 .35 13.37 2.09 3.99 15.22 13.37 28.35 10.93 30.09"
				fill="#000"
				stroke="#fff"
				strokeMiterlimit="10"
				strokeWidth="0.5"
				shapeRendering="geometricPrecision"
			/>
		</svg>
	);
};

export default Chevron;
