import clsx from "clsx";
import React, { forwardRef } from "react";
import Chevron from "../assets/icons/Chevron";
import useCursorPosition from "../hooks/useCursorPosition";
import { useCursorStore } from "../state/useStore";
import { isDesktop } from "react-device-detect";

const Cursor = forwardRef(() => {
	const { cursorX, cursorY } = useCursorPosition();
	const visible = useCursorStore((state) => state.visible);
	const direction = useCursorStore((state) => state.direction);
	return (
		isDesktop && (
			<div
				className={clsx("absolute z-[99] -translate-y-1/2 pointer-events-none", {
					hidden: !visible,
				})}
				style={{ left: `${cursorX}px`, top: `${cursorY}px` }}
			>
				{direction === "left" && <Chevron />}
				{direction === "right" && <Chevron className={"rotate-180"} />}
				{direction === "up" && <Chevron direction={"y"} />}
				{direction === "down" && <Chevron direction={"y"} className={"rotate-180"} />}
			</div>
		)
	);
});

export default Cursor;
