import clsx from "clsx";
import { Link, navigate } from "gatsby";
import React, { forwardRef } from "react";
import Logo from "../assets/icons/Logo";
import { useLocationStore, useNavStore, useProjectStore } from "../state/useStore";

const Header = forwardRef(() => {
	const location = useLocationStore((state) => state.location);
	const setExit = useNavStore((state) => state.setExit);
	const info = useProjectStore((state) => state.info);
	const isProject = useLocationStore((state) => state.isProject);

	return (
		<header
			className={clsx("fixed w-full h-12 px-4  flex-shrink-0 z-40", "md:px-12 md:h-18")}
		>
			{isProject && <div className={"absolute w-screen h-full bg-white left-0 -z-10"}></div>}
			<div className={"h-full flex justify-between items-center bg-white"}>
				<h1 className={"hover:text-brand"}>
					<button
						aria-label="Home"
						onClick={() => {
							setExit(0);
							navigate("/", { state: { direction: "default" } });
						}}
					>
						<Logo />
					</button>
				</h1>
				{!info && (
					<>
						{location === "/info/" ? (
							<Link
								to={window.previousPath}
								aria-label="Close Info"
								className={"hover:text-brand"}
							>
								Close
							</Link>
						) : (
							<button
								aria-label="Open Info"
								onClick={() => {
									setExit(0);
									navigate("/info", { state: { direction: "default" } });
								}}
								className={"hover:text-brand"}
							>
								Info
							</button>
						)}
					</>
				)}
			</div>
		</header>
	);
});

export default Header;
