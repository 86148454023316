import clsx from "clsx";
import { navigate } from "gatsby";
import React, { forwardRef } from "react";
import { useNavStore, useCursorStore } from "../state/useStore";
import { isDesktop } from "react-device-detect";

const Nav = forwardRef(() => {
	const previous = useNavStore((state) => state.previous);
	const next = useNavStore((state) => state.next);
	const setExit = useNavStore((state) => state.setExit);
	const setCursorVisible = useCursorStore((state) => state.setVisible);
	const setCursorDirection = useCursorStore((state) => state.setDirection);
	return (
		isDesktop && (
			<>
				<button
					className={clsx(
						"fixed w-1/5 p-4 h-full hidden justify-start items-center z-30 cursor-none left-0 md:flex "
					)}
					onClick={() => {
						setExit(100);
						navigate(`/${previous}`, { state: { direction: "left" } });
					}}
					onMouseEnter={() => {
						setCursorDirection("left");
						setCursorVisible(true);
					}}
					onMouseLeave={() => setCursorVisible(false)}
					aria-label={"Previous Project"}
				/>
				<button
					className={clsx(
						"fixed w-1/5 p-4 h-full hidden justify-end items-center cursor-none z-30 right-0 md:flex"
					)}
					onClick={() => {
						setExit(-100);
						navigate(`/${next}`, { state: { direction: "right" } });
					}}
					onMouseEnter={() => {
						setCursorDirection("right");
						setCursorVisible(true);
					}}
					onMouseLeave={() => setCursorVisible(false)}
					aria-label={"Next Project"}
				/>
			</>
		)
	);
});

export default Nav;
