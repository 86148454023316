import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "@reach/router";
import Nav from "../components/Nav";
import { useLocationStore } from "../state/useStore";
import Cursor from "../components/Cursor";

const Layout = ({ children }) => {
	const location = useLocation();
	const isProject = useLocationStore((state) => state.isProject);
	const setLocation = useLocationStore((state) => state.setLocation);
	const setLastLocation = useLocationStore((state) => state.setLastLocation);
	const setIsProject = useLocationStore((state) => state.setIsProject);

	useEffect(() => {
		const home = ["/", "/grid/", "/info/"];
		setLocation(location.pathname);
		setIsProject(home.includes(location.pathname) ? false : true);
	}, [location, setIsProject, setLocation]);

	useEffect(() => {
		window.previousPath ? setLastLocation(window.previousPath) : setLastLocation("/");
	}, [window.previousPath, setLastLocation]);

	useEffect(() => {
		const setHeight = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		};
		window.addEventListener("resize", setHeight);
		setHeight();
		return () => window.removeEventListener("resize", setHeight);
	}, []);

	return (
		<div
			className={
				"selection:bg-brand font-sans leading-none text-sm"
			}
		>
			<AnimatePresence initial={true} mode={"popLayout"}>
				<Header key={"header-component"} />
				<Cursor key={"cursor-component"} />
				{isProject && <Nav key={"nav-component"}></Nav>}
				{children}
				<Footer key={"footer-component"} />
			</AnimatePresence>
		</div>
	);
};

export default Layout;
