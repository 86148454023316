import "./src/styles/global.css";
import React from "react";
import Layout from "./src/layout/Layout";

export const wrapPageElement = ({ props, element }) => (
	<Layout {...props}>{element}</Layout>
);

export const onRouteUpdate = ({ prevLocation }) =>
	(window.previousPath = prevLocation ? prevLocation.pathname : null);
