import { useState, useEffect } from "react";

const useCursorPosition = () => {
	const hasWindow = typeof window !== "undefined";
	const [cursorPosition, setCursorPosition] = useState({ cursorX: 0, cursorY: 0 });

	useEffect(() => {
		if (hasWindow) {
			const cursorPosition = (e) => {
				setCursorPosition({
					cursorX: e.clientX,
					cursorY: e.clientY,
				});
			};
			window.addEventListener("mousemove", cursorPosition);
			return () => window.removeEventListener("mousemove", cursorPosition);
		}
	}, [hasWindow]);

	return cursorPosition;
};

export default useCursorPosition;
