import React from "react";

const Logo = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="200"
			height="21.08"
			viewBox="0 0 200 21.08"
			className={"fill-black hover:fill-brand h-full w-56 md:w-64"}
		>
			<desc>Takagicapéran</desc>
			<path d="m145.71,1.47c.42-.2.71-.49.71-.76,0-.42-.32-.71-.66-.71-.25,0-.49.15-.76.37l-3.04,2.43.22.37,3.53-1.69Z" />
			<path d="m22.65,5.52h2.04v-.86h-6.14v.86h2.04l-5.4,14.43h-1.77v.86h4.98v-.86h-2.16l1.89-5.13h6.95l1.89,5.13h-2.11v.86h4.98v-.86h-1.79l-5.4-14.43Zm-4.2,8.44l3.17-8.52,3.14,8.52h-6.31Z" />
			<polygon points="194.55 4.66 194.55 5.52 196.83 5.52 196.83 18.83 186.48 4.66 183.38 4.66 183.38 5.52 185.62 5.52 185.62 19.95 183.38 19.95 183.38 20.81 188.88 20.81 188.88 19.95 186.57 19.95 186.57 6.41 197.18 20.89 197.77 20.89 197.77 5.52 200 5.52 200 4.66 194.55 4.66" />
			<polygon points="0 8.37 .98 8.37 .98 5.52 6.06 5.52 6.06 19.95 3.66 19.95 3.66 20.81 9.45 20.81 9.45 19.95 7.07 19.95 7.07 5.52 12.12 5.52 12.12 8.37 13.11 8.37 13.11 4.66 0 4.66 0 8.37" />
			<path d="m55.34,5.52h2.04v-.86h-6.14v.86h2.04l-5.4,14.43h-3.75l-5.84-8.42,5.87-6.01h1.91v-.86h-5.57v.86h2.45l-8.3,8.52V5.52h2.28v-.86h-5.52v.86h2.23v14.43h-2.23v.86h5.52v-.86h-2.28v-4.79l2.92-2.97,5.92,8.62h7.61v-.86h-2.16l1.89-5.13h6.95l1.89,5.13h-2.11v.86h4.98v-.86h-1.79l-5.4-14.43Zm-4.2,8.44l3.17-8.52,3.14,8.52h-6.31Z" />
			<path d="m97.83,20.2c-4.17,0-7.07-3.41-7.07-7.36s2.9-7.56,6.97-7.56c2.68,0,4.49,1.47,4.81,2.55-.44.07-.76.44-.76.91,0,.49.39.88.88.88.59,0,1.01-.47,1.01-1.15,0-1.96-2.6-4.07-5.94-4.07-4.37,0-8,3.61-8,8.47,0,4.32,3.19,8.22,8.1,8.22,2.23,0,4.25-.83,5.87-2.38l-.54-.69c-1.5,1.4-3.26,2.18-5.33,2.18" />
			<path d="m174.62,5.52h2.04v-.86h-6.14v.86h2.04l-5.4,14.43h-4.15l-4.03-6.33c2.33-.54,3.9-2.09,3.9-4.44,0-2.63-2.14-4.52-5.52-4.52h-6.04v.86h2.23v14.43h-2.23v.86h5.52v-.86h-2.28v-6.14c.66.02,1.55.02,2.43.02.32,0,.66-.02.93-.05l4.39,7.02h8.05v-.86h-2.16l1.89-5.13h6.95l1.89,5.13h-2.11v.86h4.98v-.86h-1.79l-5.4-14.43Zm-17.57,7.46c-.86,0-1.74-.02-2.48-.05v-7.41h2.72c2.82,0,4.57,1.42,4.57,3.68,0,2.48-1.99,3.78-4.81,3.78m13.38.98l3.17-8.52,3.14,8.52h-6.31Z" />
			<path d="m114.42,5.52h2.04v-.86h-6.14v.86h2.04l-5.4,14.43h-1.77v.86h4.98v-.86h-2.16l1.89-5.13h6.95l1.89,5.13h-2.11v.86h4.98v-.86h-1.79l-5.4-14.43Zm-4.2,8.44l3.17-8.52,3.14,8.52h-6.31Z" />
			<path d="m129.14,4.66h-5.96v.86h2.23v14.43h-2.23v.86h5.57v-.86h-2.33v-5.4c.56.02,1.82.05,2.77.05,3.51,0,5.74-1.94,5.74-5.01s-2.43-4.93-5.79-4.93m.07,9.08c-.98,0-2.18-.02-2.8-.05V5.52h2.77c2.77,0,4.74,1.64,4.74,4.1s-1.69,4.12-4.71,4.12" />
			<polygon points="81.38 5.52 83.74 5.52 83.74 19.95 81.38 19.95 81.38 20.81 87.13 20.81 87.13 19.95 84.75 19.95 84.75 5.52 87.13 5.52 87.13 4.66 81.38 4.66 81.38 5.52" />
			<polygon points="137.09 5.52 139.33 5.52 139.33 19.95 137.09 19.95 137.09 20.81 148.46 20.81 148.46 17.23 147.48 17.23 147.48 19.95 140.33 19.95 140.33 13.01 146.05 13.01 146.05 12.13 140.33 12.13 140.33 5.52 147.48 5.52 147.48 8.25 148.46 8.25 148.46 4.66 137.09 4.66 137.09 5.52" />
			<path d="m72.13,14.24h4.96v3.95c-1.74,1.33-3.58,1.96-5.55,1.96-3.8,0-6.87-2.9-6.87-7.22s2.82-7.66,7.17-7.66c2.36,0,4.15,1.08,4.74,2.09-.37.17-.54.49-.54.86,0,.49.37.88.86.88.54,0,.93-.42.93-1.06,0-1.6-2.41-3.66-5.96-3.66-4.34,0-8.22,3.39-8.22,8.54s3.73,8.08,7.85,8.08c2.21,0,3.95-.66,5.62-1.91v1.72h.96v-7.44h-5.94v.86Z" />
		</svg>
	);
};

export default Logo;
