import clsx from "clsx";
import React from "react";
import { useFilterStore } from "../state/useStore";

const Filter = ({ children, last, active }) => {
	const toggleFilter = useFilterStore((state) => state.toggleFilter);
	const clearFilters = useFilterStore((state) => state.clearFilters);
	return (
		<>
			<button
				className={clsx("text-black hover:text-brand", { underline: active })}
				onClick={() => {
					clearFilters();
					toggleFilter(children);
				}}
			>
				{children}
			</button>
			{!last && <span>,&nbsp;</span>}
		</>
	);
};

export default Filter;
